import React, { useEffect, useState } from "react";
import { Autocomplete, Button, Paper, TextField } from "@mui/material";
import { BackendItem, Item, Lending } from "../../types";
import { useData } from "../../utils/useData";
import { API_URL, BUTTON_PROPS } from "../../utils/utils";
import { useNavigate, useParams } from "react-router";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import HideImageIcon from "@mui/icons-material/HideImage";
import Rotate90DegreesCwIcon from "@mui/icons-material/Rotate90DegreesCw";
import axios from "axios";
import "./EditItem.css";
import { Link } from "react-router-dom";
import MaterialBreadcrumbs from "../../MaterialBreadcrumbs/MaterialBreadcrumbs";
import { MANAGE_ITEMS_LINK } from "./ManageItems";

const EMPTY_ITEM: Item = {
  category: "",
  label: "",
  description: "",
  marking: "",
  count: 1,
  storageLocation: { site: "", place: "" },
};

// async/promise function for retrieving image dimensions for a URL
function imageSize(url: string) {
  const img = document.createElement("img");

  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      // Natural size is the actual image size regardless of rendering.
      // The 'normal' `width`/`height` are for the **rendered** size.
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      // Resolve promise with the width and height
      resolve({ width, height });
    };

    // Reject promise on error
    img.onerror = reject;
  });

  // Setting the source makes it start downloading and eventually call `onload`
  img.src = url;

  return promise as any;
}

function EditItem() {
  const [item, setItem] = useState<Item | BackendItem | undefined>();

  const [selectedFile, setSelectedFile] = useState<File>();
  const [height, setHeight] = useState<number | undefined>();
  const [width, setWidth] = useState<number | undefined>();
  const [rotation, setRotation] = useState<number>(0);

  const [data, error, mutate] = useData();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!item) {
      if (data && id) {
        setItem(data.items.find((item: any) => String(item.id) === id));
      } else if (!id) {
        setItem(EMPTY_ITEM);
      }
    }
  }, [data, id, item]);

  async function saveAndNavigate() {
    if (id)
      //editing
      await axios.put(`${API_URL}item.php`, item);
    //creating
    else await axios.post(`${API_URL}item.php`, item);
    await mutate();
    navigate("/items");
  }

  async function deleteItem() {
    if (item?.id) {
      await axios.delete(`${API_URL}item.php`, {
        params: {
          id: item.id,
        },
      });
      await mutate();
    }
    navigate("/items");
  }

  let imgScale = 1;
  if (height) imgScale = 400 / height;

  return (
    <>
      <MaterialBreadcrumbs
        currentPageTitle={item ? (item.id ? item.label : "Hinzufügen") : ""}
        previousLinks={[MANAGE_ITEMS_LINK]}
      />
      {item && data && (
        <>
          <h3>Allgemeine Infos</h3>
          <p>
            <TextField
              type="text"
              label="Bezeichnung"
              value={item.label}
              fullWidth
              onChange={(e) => setItem({ ...item, label: e.target.value })}
            />
          </p>
          <p>
            <Autocomplete
              value={item.category}
              options={data.categories ?? []}
              onInputChange={(e, newValue) =>
                setItem({
                  ...item,
                  category: newValue,
                })
              }
              renderInput={(params) => (
                <TextField {...params} label="Kategorie" />
              )}
            />
          </p>
          <Paper style={{ padding: "10px" }}>
            {selectedFile && (
              <img
                className={`item-image2 2rotate${rotation}`}
                src={URL.createObjectURL(selectedFile)}
                style={{
                  transform: `scale(${imgScale})`,
                }}
              />
            )}
            {selectedFile && (
              <Button onClick={() => setSelectedFile(undefined)}>
                <HideImageIcon />
              </Button>
            )}
            {selectedFile && (
              <Button onClick={() => setRotation((rotation + 90) % 360)}>
                <Rotate90DegreesCwIcon />
              </Button>
            )}
            {rotation}
            {!selectedFile && (
              <Button component="label">
                <AddAPhotoIcon />
                <input
                  accept="image/*"
                  type="file"
                  hidden
                  onChange={async (e) => {
                    if (e.target?.files && e.target.files[0]) {
                      setSelectedFile(e.target.files[0]);
                      const imgSize = await imageSize(
                        URL.createObjectURL(e.target.files[0])
                      );
                      setWidth(imgSize.width);
                      setHeight(imgSize.height);
                    }
                  }}
                />
              </Button>
            )}
          </Paper>
          <p>
            <TextField
              type="text"
              label="Beschreibung"
              value={item.description}
              multiline
              fullWidth
              onChange={(e) =>
                setItem({ ...item, description: e.target.value })
              }
            />
          </p>
          <p>
            <TextField
              type="number"
              label="Anzahl"
              value={item.count}
              onChange={(e) =>
                setItem({
                  ...item,
                  count: Math.max(e.target.value as unknown as number, 0),
                })
              }
            />
          </p>
          <p>
            <TextField
              type="text"
              label="Beschriftung"
              value={item.marking}
              fullWidth
              onChange={(e) => setItem({ ...item, marking: e.target.value })}
            />
          </p>
          <p>
            <Autocomplete
              value={item.storageLocation.site}
              options={Object.keys(data.sites)}
              onInputChange={(e, newValue) =>
                setItem({
                  ...item,
                  storageLocation: { ...item.storageLocation, site: newValue },
                })
              }
              renderInput={(params) => <TextField {...params} label="Ort" />}
            />
          </p>
          <p>
            <Autocomplete
              value={item.storageLocation.place}
              options={data.sites[item.storageLocation.site] ?? []}
              disabled={item.storageLocation.site === ""}
              onInputChange={(e, newValue) =>
                setItem({
                  ...item,
                  storageLocation: { ...item.storageLocation, place: newValue },
                })
              }
              renderInput={(params) => <TextField {...params} label="Stelle" />}
            />
          </p>
          {item?.currentLocations && item?.countLend !== undefined && (
            <>
              <h3>Aktuelle Standorte</h3>
              <ul>
                <li>{`${item.count - item.countLend}${
                  item.countLend === 0 ? " (alle)" : ""
                } an ${item.storageLocation.site} → ${
                  item.storageLocation.place
                }`}</li>
                {item.currentLocations.map((lending) => {
                  const lendingExtended: Lending = data.lendings.find(
                    (cand: Lending) => cand.id === lending.lendingId
                  );
                  return (
                    <li key={lending.lendingId}>
                      {`${lending.count} ausgeliehen von `}
                      <Link to={`/lendings/${lending.lendingId}`}>{`${
                        lendingExtended.lender.name
                      } (${new Date(
                        lendingExtended.issueDate
                      ).toLocaleDateString("de-DE")} - ${new Date(
                        lendingExtended.returnDate
                      ).toLocaleDateString("de-DE")})`}</Link>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
          {item?.plannedLendings && (
            <>
              <h3>Geplante Ausleihen</h3>
              <ul>
                {item.plannedLendings.map((lending) => {
                  const lendingExtended: Lending = data.lendings.find(
                    (cand: Lending) => cand.id === lending.lendingId
                  );
                  return (
                    <li key={lending.lendingId}>
                      {`${lending.count} ausgeliehen von `}
                      <Link to={`/lendings/${lending.lendingId}`}>{`${
                        lendingExtended.lender.name
                      } (${new Date(
                        lendingExtended.issueDate
                      ).toLocaleDateString("de-DE")} - ${new Date(
                        lendingExtended.returnDate
                      ).toLocaleDateString("de-DE")})`}</Link>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <Button {...BUTTON_PROPS} onClick={() => navigate(-1)}>
                Abbrechen
              </Button>{" "}
              <Button {...BUTTON_PROPS} onClick={saveAndNavigate}>
                Speichern
              </Button>
            </span>
            {item?.id && (
              <Button {...BUTTON_PROPS} onClick={deleteItem}>
                Löschen
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default EditItem;
