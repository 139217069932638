import React, { useEffect, useState } from "react";
import { Lender, Lending, LendingItem } from "../../types";
import { useData } from "../../utils/useData";
import { useNavigate, useParams } from "react-router";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import { API_URL, BUTTON_PROPS } from "../../utils/utils";
import ItemTable from "../../ItemTable/ItemTable";
import MaterialBreadcrumbs from "../../MaterialBreadcrumbs/MaterialBreadcrumbs";
import { MANAGE_LENDINGS_LINK } from "./ManageLendings";

const EMPTY_LENDER: Lender = {
  handy: "",
  mail: "",
  name: "",
};

const EMPTY_LENDING: Lending = {
  lender: EMPTY_LENDER,
  items: [],
  plannedItems: [],
  status: "planned",
  issueDate: "",
  returnDate: "",
};

export const editLendingBreadcrumbTitle = (lending: Lending | undefined) =>
  lending
    ? lending.id
      ? `Ausleihe für ${lending.lender.name}`
      : "Hinzufügen"
    : "";

function EditLending() {
  const [lending, setLending] = useState<Lending | undefined>();
  const [plannedSelections, setPlannedSelections] = useState<
    Map<number, number>
  >(new Map());
  const [lendSelections, setLendSelections] = useState<Map<number, number>>(
    new Map()
  );

  const [data, error, mutate] = useData();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!lending) {
      if (data && id) {
        const lending: Lending = data.lendings.find(
          (item: any) => String(item.id) === id
        );
        setLending(lending);
        setLendSelections(
          new Map(lending.items.map((item) => [item.itemId, item.count]))
        );
        setPlannedSelections(
          new Map(lending.plannedItems.map((item) => [item.itemId, item.count]))
        );
      } else if (!id) {
        setLending(EMPTY_LENDING);
      }
    }
  }, [data, id, lending, setLending, plannedSelections, setPlannedSelections]);

  async function deleteLending() {
    if (lending?.id) {
      await axios.delete(`${API_URL}lending.php`, {
        params: {
          id: lending.id,
        },
      });
      await mutate();
    }
    navigate("/lendings");
  }

  async function saveAndNavigate() {
    let plannedItems: LendingItem[] = [];
    let items: LendingItem[] = [];
    plannedSelections.forEach((value, key) => {
      if (value > 0)
        plannedItems.push({
          itemId: key,
          count: value,
          description: "",
        });
    });

    lendSelections.forEach((value, key) => {
      if (value > 0)
        items.push({
          itemId: key,
          count: value,
          description: "",
        });
    });

    let lendingWithItems = {
      ...lending,
      items,
      plannedItems,
    };

    if (id)
      //editing
      await axios.put(`${API_URL}lending.php`, lendingWithItems);
    //creating
    else await axios.post(`${API_URL}lending.php`, lendingWithItems);
    await mutate();
    navigate("/lendings");
  }

  return (
    <>
      <MaterialBreadcrumbs
        currentPageTitle={editLendingBreadcrumbTitle(lending)}
        previousLinks={[MANAGE_LENDINGS_LINK]}
      />
      {lending && data && (
        <>
          {lending?.id && (
            <span>
              <Button
                {...BUTTON_PROPS}
                onClick={() => navigate(`/lendings/${lending.id}/planOverview`)}
              >
                Leih-Plan
              </Button>{" "}
              <Button
                {...BUTTON_PROPS}
                onClick={() => navigate(`/lendings/${lending.id}/contract`)}
              >
                Vertrag
              </Button>{" "}
              <Button
                {...BUTTON_PROPS}
                onClick={() => navigate(`/lendings/${lending.id}/lendOverview`)}
              >
                Ausgeliehen-Übersicht
              </Button>
            </span>
          )}
          <p>
            <TextField
              type="text"
              label="Name"
              value={lending.lender.name}
              fullWidth
              onChange={(e) =>
                setLending({
                  ...lending,
                  lender: { ...lending.lender, name: e.target.value },
                })
              }
            />
          </p>
          <p>
            <TextField
              type="text"
              label="Mail"
              value={lending.lender.mail}
              fullWidth
              onChange={(e) =>
                setLending({
                  ...lending,
                  lender: { ...lending.lender, mail: e.target.value },
                })
              }
            />
          </p>
          <p>
            <TextField
              type="text"
              label="Handy"
              value={lending.lender.handy}
              fullWidth
              onChange={(e) =>
                setLending({
                  ...lending,
                  lender: { ...lending.lender, handy: e.target.value },
                })
              }
            />
          </p>
          <p>
            <TextField
              id="date"
              label="Ausgabedatum (geplant)"
              type="date"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              value={lending.issueDate}
              onChange={(e) =>
                setLending({ ...lending, issueDate: e.target.value })
              }
            />
          </p>
          <p>
            <TextField
              id="date"
              label="Rückgabedatum (geplant)"
              type="date"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              value={lending.returnDate}
              onChange={(e) =>
                setLending({ ...lending, returnDate: e.target.value })
              }
            />
          </p>
          <ItemTable
            mode="editLending"
            plannedSelections={plannedSelections}
            setPlannedSelections={setPlannedSelections}
            lendSelections={lendSelections}
            setLendSelections={setLendSelections}
            lendingId={lending.id}
            data={data}
          />
          <>
            <Button {...BUTTON_PROPS} onClick={() => navigate(-1)}>
              Abbrechen
            </Button>{" "}
            <Button {...BUTTON_PROPS} onClick={saveAndNavigate}>
              Speichern
            </Button>{" "}
            {lending?.id && (
              <Button {...BUTTON_PROPS} onClick={deleteLending}>
                Löschen
              </Button>
            )}
          </>
        </>
      )}
    </>
  );
}

export const editLendingBreadcrumbLink = (lending: Lending | undefined) => {
  return {
    title: editLendingBreadcrumbTitle(lending),
    url: lending ? `/lendings/${lending.id}` : "",
  };
};

export default EditLending;
