import React from "react";

const FractionBar = React.memo(function ProgressBar(props: {
  valueTotal: number;
  valueFraction: number;
}) {
  const valueInPercent = (props.valueFraction / props.valueTotal) * 100;

  return (
    <div
      style={{
        border: `1px solid gray`,
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: 26,
        borderRadius: 2,
      }}
    >
      <div
        style={{
          position: "absolute",
          lineHeight: "24px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >{`${props.valueFraction} / ${props.valueTotal}`}</div>
      <div
        style={{
          height: "100%",
          maxWidth: `${valueInPercent}%`,
          background: "powderblue",
        }}
      />
    </div>
  );
});
export default FractionBar;
