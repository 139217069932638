import React, { useEffect, useState } from "react";
import { useData } from "../../utils/useData";
import { useNavigate, useParams } from "react-router";
import { Lending } from "../../types";
import "./LendingOverview.css";
import Logo from "../../Logo/Logo";
import LendingOverviewItems from "./LendingOverviewItems";
import MaterialBreadcrumbs from "../../MaterialBreadcrumbs/MaterialBreadcrumbs";
import { MANAGE_LENDINGS_LINK } from "./ManageLendings";
import { editLendingBreadcrumbLink } from "./EditLending";

type LendingOverviewMode = "plan" | "lend";

export type LendingOverviewProps = {
  mode: LendingOverviewMode;
};

export const headline = (mode: LendingOverviewMode) =>
  mode === "plan" ? "Geplante Ausleihe" : "Ausgeliehenes Material";

function LendingOverview(props: LendingOverviewProps) {
  const [lending, setLending] = useState<Lending | undefined>();

  const [data, error, mutate] = useData();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!lending) {
      if (data) {
        const lending: Lending = data.lendings.find(
          (item: any) => String(item.id) === id
        );
        setLending(lending);
      }
    }
  }, [data, id, lending, setLending]);

  let itemSet;
  if (lending) {
    if (props.mode === "plan") itemSet = lending.plannedItems;
    if (props.mode === "lend") itemSet = lending.items;
  }

  return (
    <>
      <MaterialBreadcrumbs
        currentPageTitle={headline(props.mode)}
        previousLinks={[
          MANAGE_LENDINGS_LINK,
          editLendingBreadcrumbLink(lending),
        ]}
      />
      <Logo />
      {lending && (
        <>
          <h1>
            {props.mode === "plan" && "Geplante Ausleihe"}
            {props.mode === "lend" && "Ausgeliehenes Material"} für{" "}
            {lending.lender.name}
          </h1>
          {(lending.issueDate || lending.returnDate) && (
            <h4>
              {lending.issueDate
                ? new Date(lending.issueDate).toLocaleDateString("de-DE")
                : ""}{" "}
              -{" "}
              {lending.returnDate
                ? new Date(lending.returnDate).toLocaleDateString("de-DE")
                : ""}
            </h4>
          )}
          {itemSet && <LendingOverviewItems items={itemSet} />}
        </>
      )}
    </>
  );
}

export default LendingOverview;
