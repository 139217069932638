import React from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ManageItems from "./pages/Item/ManageItems";
import { useLogin } from "./utils/useLogin";
import EditItem from "./pages/Item/EditItem";
import Home from "./pages/Home";
import ManageLendings from "./pages/Lending/ManageLendings";
import EditLending from "./pages/Lending/EditLending";
import NavBar from "./NavBar/NavBar";
import LendingOverview from "./pages/Lending/LendingOverview";
import RegalZettel from "./pages/RegalZettel/RegalZettel";
import LendingContract from "./pages/Lending/LendingContract";

function App() {
  const [loginStatus, loginForm, logout] = useLogin();

  return (
    <>
      <div style={{ padding: "15px", maxWidth: "1000px", margin: "0 auto" }}>
        <>
          {(loginStatus === "initial" || loginStatus === "loading") &&
            "Lädt..."}
          {loginStatus === "not_logged_in" && loginForm}
          {loginStatus === "logged_in" && (
            <BrowserRouter>
              <NavBar
                pages={[
                  { url: "/items", title: "Material verwalten" },
                  { url: "/lendings", title: "Ausleihen verwalten" },
                ]}
              />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/items" element={<ManageItems />} />
                <Route path="/items/add" element={<EditItem />} />
                <Route path="/items/:id" element={<EditItem />} />
                <Route path="/lendings" element={<ManageLendings />} />
                <Route path="/lendings/add" element={<EditLending />} />
                <Route path="/lendings/:id" element={<EditLending />} />
                <Route
                  path="/lendings/:id/planOverview"
                  element={<LendingOverview mode="plan" />}
                />
                <Route
                  path="/lendings/:id/lendOverview"
                  element={<LendingOverview mode="lend" />}
                />
                <Route
                  path="/lendings/:id/contract"
                  element={<LendingContract />}
                />
                <Route path="/regalzettel" element={<RegalZettel />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </BrowserRouter>
          )}
        </>
      </div>
    </>
  );
}

export default App;
