import React from "react";
import {
  AppBar,
  Container,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import "./NavBar.css";
import { LOGO } from "../utils/utils";

export type NavBarPage = {
  title: string;
  url: string;
};

export type NavBarProps = {
  pages: NavBarPage[];
};

function NavBar(props: NavBarProps) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div style={{ marginBottom: "1em" }} className="nav-bar">
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img
              src={LOGO}
              height="64px"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer", padding: "5px 0" }}
            />
            {props.pages.map((page) => (
              <MenuItem onClick={() => navigate(page.url)} key={page.url}>
                <Typography
                  textAlign="center"
                  style={{
                    textDecoration:
                      location.pathname === page.url ? "underline" : "",
                  }}
                >
                  {page.title}
                </Typography>
              </MenuItem>
            ))}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default NavBar;
