import { LendingStatus } from "../types";

export function lendingStatusDisplay(key: LendingStatus) {
  switch (key) {
    case "planned":
      return "geplant";
    case "active":
      return "aktiv";
    case "all_returned":
      return "beendet";
  }
  return key;
}
