import React from "react";
import LinkList, { LinkListItemType } from "../LinkList/LinkList";

const LINKS: LinkListItemType[] = [
  { url: "/items", title: "Material verwalten" },
  { url: "/items/add", title: "Material hinzufügen" },
  { url: "/lendings", title: "Ausleihen verwalten" },
  { url: "/lendings/add", title: "Ausleihe hinzufügen" },
  { url: "/regalzettel", title: "Regalzettel drucken" },
];

function Home() {
  return (
    <div style={{ padding: "0 20px" }}>
      <LinkList prependUrl="" links={LINKS} />
    </div>
  );
}

export default Home;
