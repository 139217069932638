import React from "react";
import { BUTTON_PROPS } from "../../utils/utils";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router";
import ItemTable from "../../ItemTable/ItemTable";
import { useData } from "../../utils/useData";
import MaterialBreadcrumbs, {
  MaterialBreadcrumbLink,
} from "../../MaterialBreadcrumbs/MaterialBreadcrumbs";

function ManageItems() {
  const navigate = useNavigate();
  const [data, error, mutate] = useData();

  return (
    <>
      <MaterialBreadcrumbs
        currentPageTitle="Material verwalten"
        previousLinks={[]}
      />
      <ItemTable mode="manageItems" data={data} />
      <Button
        {...BUTTON_PROPS}
        startIcon={<AddIcon />}
        onClick={() => navigate("/items/add")}
      >
        Material hinzufügen
      </Button>
    </>
  );
}

export const MANAGE_ITEMS_LINK: MaterialBreadcrumbLink = {
  title: "Material verwalten",
  url: "/items",
};

export default ManageItems;
