import React from "react";
import "./LinkList.css";
import { Link } from "react-router-dom";

export type LinkListItemType = {
  url: string;
  title: string;
};

export type LinkListProps = {
  prependUrl: string;
  links: LinkListItemType[];
};

function LinkListItem(props: LinkListItemType) {
  return (
    <li className="link-list_item">
      <Link to={props.url} className="link-list_item_link">
        {props.title}
      </Link>
    </li>
  );
}

function LinkList(props: LinkListProps) {
  return (
    <ul className="link-list">
      {props.links.map((link) => (
        <LinkListItem
          title={link.title}
          url={props.prependUrl + link.url}
          key={link.url}
        />
      ))}
    </ul>
  );
}

export default LinkList;
