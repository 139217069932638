import React, { useMemo } from "react";
import { Lending } from "../../types";
import { useData } from "../../utils/useData";
import { useParams } from "react-router";
import Logo from "../../Logo/Logo";
import LendingOverviewItems from "./LendingOverviewItems";
import "./LendingContract.css";
import { BRANDING } from "../../utils/utils";
import MaterialBreadcrumbs from "../../MaterialBreadcrumbs/MaterialBreadcrumbs";
import { MANAGE_LENDINGS_LINK } from "./ManageLendings";
import { editLendingBreadcrumbLink } from "./EditLending";

export type LendingContractProps = {};

function LendingContract(props: LendingContractProps) {
  const [data, error, mutate] = useData();
  const { id } = useParams();

  const lending: Lending | undefined = useMemo(
    () => data?.lendings?.find((item: any) => String(item.id) === id),
    [data?.lendings, id]
  );

  if (!lending) {
    return null;
  }

  return (
    <>
      <MaterialBreadcrumbs
        currentPageTitle="Leihvertrag"
        previousLinks={[
          MANAGE_LENDINGS_LINK,
          editLendingBreadcrumbLink(lending),
        ]}
      />
      <div className="lending-contract_header">
        <Logo />
        <h1>Verleihvertrag mit {lending.lender.name}</h1>
      </div>
      <p className="blocksatz">
        Ich, {lending.lender.name}, verpflichte mich, das unten aufgeführte
        Material{" "}
        {lending.returnDate && (
          <>
            bis zum {new Date(lending.returnDate).toLocaleDateString("de-DE")}{" "}
            (Datum der vereinbarten Rückgabe){" "}
          </>
        )}
        dem Ausleiher (siehe unten) wieder zurück zu geben. Im Falle der
        Versäumnis des Rückgabetermins, einer Beschädigung oder des Verlusts
        werde ich das aufgeführte Material ersetzen. Ich verleihe das Material
        nicht ohne Zustimmung weiter.
      </p>
      <p>Auflistung des verliehenen Materials:</p>
      <div style={{ paddingLeft: "10px" }}>
        <LendingOverviewItems items={lending.plannedItems} />
      </div>
      <div className="lending-contract_signature-line">
        <div className="lending-contract_underline-top">Ort, Datum</div>
        <div className="lending-contract_underline-top">
          Unterschrift des Leihenden
        </div>
        <div className="lending-contract_underline-top">
          Unterschrift {BRANDING}
        </div>
      </div>
      <div className="lending-contract_footer print-footer">
        {BRANDING === "AHP" && (
          <>
            <p>Arbeitsgemeinschaft Hamburger Pfadfinder*innenverbände e.V.</p>
            <p>Barmbeker Straße 71, 22303 Hamburg</p>
          </>
        )}
        {BRANDING === "JBN" && (
          <>
            <p>VCP-Stamm Johannes Bugenhagen</p>
            <p>Quedlinburger Weg 100, 22455 Hamburg</p>
          </>
        )}
      </div>
    </>
  );
}

export default LendingContract;
