import axios from "axios";
import { Item, Lending } from "../types";
import ahpLogo from "./AHP_Logo_MT.png";
import jbnLogo from "./jonny-buh-logo-t.gif";

export const API_URL = "/api/";
export const BRANDING: "AHP" | "JBN" = "JBN";
// @ts-ignore
export const LOGO = BRANDING === "AHP" ? ahpLogo : jbnLogo;

export const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export const BUTTON_PROPS = {
  variant: "contained",
  color: "grey",
} as any; //grey entspricht nicht dem typ, funktioniert aber

export const findLending = (data: any, id: string): Lending | undefined =>
  data.lendings.find((item: any) => String(item.id) === id);

export const storageLocationToString = (item: Item) =>
  `${item.storageLocation.site} → ${item.storageLocation.place}`;
