import React, { useMemo } from "react";
import { useData } from "../../utils/useData";
import { Item, StorageLocation } from "../../types";
import "./RegalZettel.css";
import { BUTTON_PROPS } from "../../utils/utils";
import { Button } from "@mui/material";
import Logo from "../../Logo/Logo";

const locationString = (location: StorageLocation) =>
  `${location.site}: ${location.place}`;

function RegalZettel() {
  const [data, error, mutate] = useData();

  const locations = useMemo(() => {
    let locations = new Map<string, Item[]>();
    if (!data) return [];
    data.items.forEach((item: Item) => {
      let arr = locations.get(locationString(item.storageLocation));
      if (!arr) {
        arr = [];
      }
      arr.push(item);
      locations.set(locationString(item.storageLocation), arr);
    });
    return Array.from(locations).sort(([aLocation], [bLocation]) =>
      aLocation.localeCompare(bLocation)
    );
  }, [data]);

  if (!data) return null;

  return (
    <div className="regal-zettel">
      <Button {...BUTTON_PROPS} onClick={window.print}>
        Drucken
      </Button>
      {locations.map((location) => (
        <div className="regal-zettel_page">
          <Logo />
          <h1 className="regal-zettel_location">{location[0]}</h1>
          {location[1].map((item: Item) => (
            <div key={item.id} className="regal-zettel_item">
              <div className="regal-zettel_item_label">
                {item.count}* {item.label}
              </div>
              <div className="regal-zettel_item_description">
                {item.description}
              </div>
              <div className="regal-zettel_item_marking">
                {item.marking && <>Beschriftung: {item.marking}</>}
              </div>
            </div>
          ))}
          <div className="regal-zettel_footer print-footer">
            Stand: {new Date().toLocaleDateString("de-DE")}
            <span className="regal-zettel_footer_arrow">🡖</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default RegalZettel;
