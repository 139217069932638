import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./MaterialBreadcrumbs.css";

export type MaterialBreadcrumbLink = {
  title: string;
  url: string;
};

export type MaterialBreadcrumbsProps = {
  currentPageTitle: string;
  previousLinks: MaterialBreadcrumbLink[];
};

function MaterialBreadcrumbs(props: MaterialBreadcrumbsProps) {
  return (
    <Breadcrumbs aria-label="breadcrumb" className="material-breadcrumbs">
      <Link to="/">Start</Link>
      {props.previousLinks.map((link: MaterialBreadcrumbLink) => (
        <Link to={link.url}>{link.title}</Link>
      ))}
      <Typography color="text.primary">{props.currentPageTitle}</Typography>
    </Breadcrumbs>
  );
}

export default MaterialBreadcrumbs;
