import React, { useMemo, useState } from "react";
import { Button } from "@mui/material";
import { BUTTON_PROPS } from "../../utils/utils";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { GridColumnVisibilityModel } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import { useData } from "../../utils/useData";
import { Lending } from "../../types";
import { lendingStatusDisplay } from "../../utils/lending";
import MaterialBreadcrumbs, {
  MaterialBreadcrumbLink,
} from "../../MaterialBreadcrumbs/MaterialBreadcrumbs";

//  { field: "status", headerName: "Status", flex: 1 },
const columns: GridColDef[] = [
  { field: "lenderName", headerName: "Name", flex: 4 },
  { field: "issueDate", headerName: "Ausgabe", flex: 2 },
  { field: "returnDate", headerName: "Rückgabe", flex: 2 },
  { field: "plannedItems", headerName: "geplant", flex: 1 },
  { field: "items", headerName: "ausgegeben", flex: 1 },
];

function ManageLendings() {
  const navigate = useNavigate();
  const [data, error, mutate] = useData();

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});

  const rows: GridRowsProp = useMemo(
    () =>
      data && data.lendings
        ? data.lendings.map((bLending: Lending) => {
            return {
              ...bLending,
              issueDate: bLending.issueDate
                ? new Date(bLending.issueDate).toLocaleDateString("de-DE")
                : "",
              returnDate: bLending.returnDate
                ? new Date(bLending.returnDate).toLocaleDateString("de-DE")
                : "",
              lenderName: bLending.lender.name,
              status: lendingStatusDisplay(bLending.status),
              items: bLending.items
                .map((item) => item.count)
                .reduce(
                  (previousValue, currentValue) => previousValue + currentValue,
                  0
                ),
              plannedItems: bLending.plannedItems
                .map((item) => item.count)
                .reduce(
                  (previousValue, currentValue) => previousValue + currentValue,
                  0
                ),
            };
          })
        : [],
    [data]
  );

  return (
    <>
      <MaterialBreadcrumbs
        currentPageTitle="Ausleihen verwalten"
        previousLinks={[]}
      />
      <DataGrid
        columns={columns}
        rows={rows}
        autoHeight
        style={{ marginBottom: "1em" }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        onRowClick={(params) => navigate(`/lendings/${params.row.id}`)}
      />
      <Button
        {...BUTTON_PROPS}
        startIcon={<AddIcon />}
        onClick={() => navigate("/lendings/add")}
      >
        Ausleihe hinzufügen
      </Button>
    </>
  );
}

export const MANAGE_LENDINGS_LINK: MaterialBreadcrumbLink = {
  title: "Ausleihen verwalten",
  url: "/lendings",
};

export default ManageLendings;
