import React, { useMemo } from "react";
import { Item, LendingItem } from "../../types";
import { useData } from "../../utils/useData";
import "./LendingOverviewItems.css";
import { storageLocationToString } from "../../utils/utils";

export type LendingOverviewItemProps = {
  items: LendingItem[];
};

function LendingOverviewItems(props: LendingOverviewItemProps) {
  const [data, error, mutate] = useData();
  const sortedAndEnhancedItems: Item[] | undefined = useMemo(
    () =>
      props.items
        ? props.items
            .map((item) =>
              data.items.find((item2: Item) => item2.id === item.itemId)
            )
            .sort((a: Item, b: Item) =>
              storageLocationToString(a).localeCompare(
                storageLocationToString(b)
              )
            )
        : undefined,
    [props.items]
  );

  return (
    <div>
      {sortedAndEnhancedItems &&
        sortedAndEnhancedItems.map((item) => {
          return (
            <div className="lending-overview_item" key={item.id}>
              <div className="lending-overview_item_label">
                <strong>
                  {item.count}* {item.label}
                </strong>
              </div>
              <div className="lending-overview_item_location">
                Lagerort: {storageLocationToString(item)}
              </div>
              <div className="lending-overview_item_description">
                {item.description}
              </div>
              <div className="lending-overview_item_marking">
                {item.marking && <>Beschriftung: {item.marking}</>}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default LendingOverviewItems;
