import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { API_URL as API_BASEURL, BUTTON_PROPS } from "./utils";
import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";

export type LoginStatus = "initial" | "loading" | "logged_in" | "not_logged_in";

export function useLogin() {
  const [loginStatus, setLoginStatus] = useState<LoginStatus>("initial");
  const [loginFormUser, setLoginFormUser] = useState("");
  const [loginFormPassword, setLoginFormPassword] = useState("");

  const fetchLoginStatus = useCallback(() => {
    axios
      .get(API_BASEURL + "auth/loginCheck.php")
      .then(() => setLoginStatus("logged_in"))
      .catch(() => setLoginStatus("not_logged_in"));
  }, []);

  useEffect(() => {
    if (loginStatus === "initial") {
      fetchLoginStatus();
    }
  });

  const login = useCallback(
    (user: string, password: string) => {
      setLoginStatus("loading");
      axios
        .get(API_BASEURL + "auth/login.php", {
          params: {
            user,
            password,
          },
        })
        .then((_res) => {
          setLoginStatus("logged_in");
        })
        .catch((_err) => {
          setLoginStatus("not_logged_in");
          toast.error("Login fehlgeschlagen.");
        })
        .then(() => {
          setLoginFormPassword("");
        });
    },
    [setLoginStatus, setLoginFormPassword]
  );

  const logout = useCallback(() => {
    axios.get(API_BASEURL + "auth/logout.php").then(fetchLoginStatus);
  }, [fetchLoginStatus]);

  const loginForm = (
    <form className="login-form mt-48px">
      <p>
        <TextField
          type="text"
          value={loginFormUser}
          onChange={(e) => setLoginFormUser(e.target.value)}
          label="Benutzername"
        />
      </p>
      <p>
        <TextField
          type="password"
          value={loginFormPassword}
          onChange={(e) => setLoginFormPassword(e.target.value)}
          label="Passwort"
        />
      </p>
      <p>
        <Button
          type="submit"
          {...BUTTON_PROPS}
          onClick={(e) => {
            login(loginFormUser, loginFormPassword);
            e.preventDefault();
          }}
        >
          Login
        </Button>
      </p>
    </form>
  );

  return [loginStatus, loginForm, logout];
}
