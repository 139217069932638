import useSWR from "swr";
import { API_URL, fetcher } from "./utils";
import { useMemo } from "react";

export function useData() {
  const {
    data: dataFetch,
    error,
    mutate,
  } = useSWR(`${API_URL}allData.php`, fetcher);

  // don't rerender when data fetched but nothing changed
  const data = useMemo(() => {
    return dataFetch;
  }, [dataFetch?.dataHash]);

  return [data, error, mutate];
}
